<template>
  <div class="home">
    <h1>Server Spawn</h1>
    <h2>Under consturction</h2>
    <img class="banner" alt="server spawn logo" src="../assets/sspawn-banner.png">
    
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>

<style>
.banner {
  width: 80%
}
</style>
